.btn-info {
  color: #ffffff !important;
  background-image: -moz-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
  background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
  background-image: -ms-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
  border-color: transparent;
  background-color: #8226f5 !important;
  background-size: cover;
  background-repeat: repeat;
}

.btn-info:hover {
  color: #ffffff !important;
  background-image: -moz-linear-gradient(45deg, #680ade 0%, #c513c9 100%) !important;
  background-image: -webkit-linear-gradient(45deg, #680ade 0%, #c513c9 100%) !important;
}

input[_ngcontent-qqx-c58]:checked+.slider[_ngcontent-qqx-c58] {
  background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
}

.custom-control-input:checked~.custom-control-label::before {
  background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
}

.ngx-pagination .current {
  padding: 0.1875rem 0.625rem;
  background: #b526f0 !important;
  color: #fefefe;
  cursor: default;
}

/*.loader_wrapper{ float: left; width:100%; 
    z-index: 99999;
    position: fixed;
    margin-top: -60px;
    height: 100%;
}
.loader_wrapper::before{ content: ''; position: absolute; width: 100%; height:100%; z-index: 5; background: rgba(0,0,0,0.3); }*/

.loader_wrapper{ float: left; width:100%; }
.loader_wrapper::before{ content: ''; position: absolute; width: 100%; height:100%; z-index: 5; background: rgba(0,0,0,0.3); }
.form_loader {
  position: absolute;
  top: 50%;
  left: 17%;
  right: 0;
  margin: 0 auto;
  z-index: 99;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid  #5c3428;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; 
  animation: spin 2s linear infinite;
}


@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 1100px) {
  .form_loader{ left: 0; }
}

.batch_loader {
  position: absolute;
  top: 40%;
  left: 0%;
  right: 13%;
  margin: 0 auto;
  z-index: 99;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid  #e625ea;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; 
  animation: spin 2s linear infinite;
}



.tooltip-inner  {
  background-color: #7609f7 !important;
  text-align: justify !important; 
  /*!important is not necessary if you place custom.css at the end of your css calls. For the purpose of this demo, it seems to be required in SO snippet*/
  color: #fff;
  min-width: 350px !important;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #7609f7 !important;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #7609f7 !important;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #7609f7 !important;
}

.tooltip.left .tooltip-arrow {
  border-left-color: #7609f7 !important;
}

.fa-info-circle {
  color: #7609f7 !important;
}